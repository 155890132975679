<template>
  <div class="mobile-home">
    <div class="mobile-home-header">
      <img src="../../../assets/mobile_images/mo_contact.png" alt="" />
      <!-- <div class="mobile-header-btn">进一步了解</div> -->
    </div>
    <div class="contact-title">
      <div class="contact-button">
        <img src="../../../assets/mobile_images/mo_call.png" alt="" />
        <div class="contact-button-text">
          <p>总机：</p>
          <p>027-59907980</p>
        </div>
      </div>
      <div class="contact-button">
        <img src="../../../assets/mobile_images/mo_mail.png" alt="" />
        <div class="contact-button-text">
          <p>简历投递邮箱：</p>
          <p>tonglei@csbr.cn</p>
        </div>
      </div>
    </div>
    <div
      class="contact-list"
      v-for="(item, index) in contactList"
      :key="index"
    >
      <h2>{{ item.title }}</h2>
      <p><span class="contact-text">联系人</span>{{ item.contact }}</p>
      <p><span class="contact-text">电话</span>{{ item.tel }}</p>
      <p><span class="contact-text">邮箱</span>{{ item.mail }}</p>
      
    </div>
    
  </div>
</template>
<script>
export default {
  name: 'home',
  data() {
    return {
      pageIndex: 1,
      pageSize: 5,
      currentIndex: 0,
      contactList: [
      {
        title:'智能硬件产品',
        contact:'王先生',
        tel:'18607126616',
        mail:'wangzhiwei@csbr.cn',
      },
      {
        title:'数字化转型产品',
        contact:'曹先生',
        tel:'18872230383',
        mail:'weicao@csbr.cn',
      },
      {
        title:'市场活动',
        contact:'彭先生',
        tel:'18995555542',
        mail:'pengjinyuan@csbr.cn',
      },
      {
        title:'智能存储系列',
        contact:'程先生',
        tel:'15065188227',
        mail:'chengguohan@csbr.cn',
      },
      {
        title:'智能配送系列',
        contact:'王先生',
        tel:'13371166653',
        mail:'wanghaipeng@csbr.cn',
      },
      {
        title:'智能拣选系列',
        contact:'孟先生',
        tel:'18062509510',
        mail:'mengmin@csbr.cn',
      },
      {
        title:'恋链平台',
        contact:'何女士',
        tel:'18771137986',
        mail:'hehaohao@csbr.cn',
      },
      {
        title:'数据资产服务',
        contact:'刘女士',
        tel:'18401692329',
        mail:'liufengqin@csbr.cn',
      },
      {
        title:'SPD服务',
        contact:'袁先生',
        tel:'13986025398',
        mail:'yuanlifei@csbr.cn',
      },
      
      ],
    };
  },
  methods: {
    onTabChange(index) {
      this.currentIndex = index;
      console.log(index);
    },
    handleSizeChange(val) {
      this.pageSize = val;
    },
    handleCurrentChange(val) {
      this.pageIndex = val;
      document.body.scrollTop = document.documentElement.scrollTop = 0
    },
  },
};
</script>
<style lang="less" scoped>
.mobile-home {
  margin: 0 auto;
  background: #fff;
  .mobile-home-header {
    position: relative;
    min-height: 2.6rem;
    img {
      width: 100%;
    }
    .mobile-header-btn {
      position: absolute;
      left: 50%;
      bottom: 1.8rem;
      transform: translateX(-50%);
      width: 2.4rem;
      height: 0.72rem;
      line-height: 0.72rem;
      font-size: 0.28rem;
      text-align: center;
      color: #fff;
      background: #4fa1a4;
      border-radius: 0.04rem;
    }
  }
  .contact-list {
    margin: .16rem .16rem;
    padding: .24rem .43rem;
    text-align: left;
    background: #4FA1A4;
    border-radius: .08rem;
    box-sizing: border-box;
    h2 {
      font-size: 0.32rem;
      line-height: .4rem;
      color: #FFFFFF;
      padding-bottom: .16rem;
      margin-bottom: .16rem;
      border-bottom: 1px solid rgba(255, 255, 255, 0.4);
    }
    p{
      font-size: .28rem;
      color: #fff;
      line-height: .4rem;
      margin-top: .2rem;
      
    }
    .contact-text{
      width: 54px;
      display: inline-block;
    }
    
   
  }
  .news-pagination{
    padding: .7rem 0 ;
  }
  .contact-title{
    display: flex;
    margin: 20px;
    .contact-button{
      width: 50%;
      display: flex;
      img{
        width: 28px;
        height: 28px;
        line-height: 40px;
        margin: auto 0;
      }
      .contact-button-text{
        text-align: left;
        margin-left: 14px;
        font-size: 14px;
        color: #212121;
        letter-spacing: 0;
        line-height: 20px;
        font-weight: 500;
      }
    }
  }
}
</style>